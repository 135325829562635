<template>
    <vue-table-card :showSearchBox="false" class="m-3" title="Media List" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template slot="created_at" slot-scope="props">
            {{ props.rowData.created_at.substring(0,10) }}
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn icon="" size="xs" @click="setDelete(rowData)"/>
                <edit-btn icon="" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>

        <template #footer>
            <modal no-close-on-backdrop title="Add Media" ref="addModal" width="40r" header-color="primary">
                <s-form @submit="submitClicked" ref="form">
                    <validated-input label="Title" name="title" v-model="model.title" :rules="{required : true}"
                                     :disabled="loading"/>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Order" name="order" v-model="model.order"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-vue-select label="Status" name="status" v-model="model.status"
                                                  :rules="{required : true}" :disabled="loading"
                                                  :options="statusOptions"/>
                        </div>
                    </div>

                    <textarea-input label="Description" name="description" v-model="model.description"
                                    :disabled="loading"
                                    :rules="{required : true}"/>
                    <validated-file-input label="Logo" name="logo" v-model="model.logo" :disabled="loading"/>
                    <validated-file-input label="Image" name="image" v-model="model.image" :disabled="loading"/>
                    <validated-input label="Link" name="link" v-model="model.link" :rules="{required : false}"
                                     :disabled="loading"/>
                    <validated-input label="Youtube" name="youtube" v-model="model.youtube" :rules="{required : false}"
                                     :disabled="loading"/>
                    <btn size="sm" text="Save" :loading="loading" loading-text="Saving.."/>

                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the Image. Are you sure?</p>
                <template #loading>
                    <delete-animation/>
                    Please wait while we delete the item.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'MediaListing',
    data () {
        return {
            loading      : false,
            listUrl      : urls.admin.media.list,
            deleteUrl    : urls.admin.media.delete,
            deletingItem : {
                id : ''
            },
            addUrl : urls.admin.media.add,
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'title',
                    sortField : 'title',
                    title     : 'Title'
                },
                {
                    name       : '__slot:created_at',
                    sortField  : 'created_at',
                    title      : 'Created Date',
                    titleClass : ''
                },
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ],
            model : {
                title       : '',
                order       : '',
                status      : '',
                description : '',
                logo        : '',
                image       : '',
                link        : '',
                youtube     : ''
            },
            statusOptions : [
                { value : 'Published', label : 'Published' },
                { value : 'UnPublished', label : 'UnPublished' }
            ]
        };
    },
    methods : {
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.media.add, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess();
                that.loading = false;
            } else {
                that.formError(json);
                that.loading = false;
            }
            that.loading = false;
        },
        viewDetails (rowData) {
            this.$router.push('/media/' + rowData.id + '/details/');
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$notify('Saved Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json) {
            this.$refs.form.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type : 'danger'
            });
        }
    }
};
</script>

<style scoped>

</style>
